<script lang="ts" setup>
import { useStyle3d } from './style3d.ts';
import type { ID, Style3dOptions } from './typing.ts';
import { onMounted, watch } from 'vue';

const props = defineProps<{
  options: Style3dOptions
}>();

const emit = defineEmits<{
  loaded: []
  ready: [ready: boolean]
  'material-selected': [id: ID]
}>();

const style3dInstance = useStyle3d();

watch(() => style3dInstance.isReady, (v) => {
  emit('ready', v);
});

onMounted(async () => {
  await style3dInstance.initStyle3d({
    ...props.options,
    events: {
      onMaterialSelected(selectedMaterial) {
        const { materialInfo } = selectedMaterial;
        emit('material-selected', materialInfo.id);
      },
      onNothingSelected() {
        emit('material-selected', '');
      }
    }
  })
    .then(() => {
      emit('loaded');
    });
});


const { getStyle3dSDKInstance } = useStyle3d();
const doDrop = async (event:any) => {
  console.log('doDrop', event);
  const style3dSDK = getStyle3dSDKInstance();
  const res = await style3dSDK.doDropDecal(event);
  console.log(res);
  const res1 = await style3dSDK.getDecalList();
  console.log('getDecalList', res1);
  const res2 = await style3dSDK.getMaterialList();
  console.log('getMaterialList', res2);

  const res3 = await style3dSDK.getDecalState();
  console.log('getDecalState', res3);
};

const onLincproMouseDown = async (event: any) => {
  console.log(event);
};

const onLincproMouseUp = async (event: any) => {
  // todo 记录鼠标抬起的位置，addDecalByEvent需要用
  console.log(event);
};
</script>

<template>
  <div
    style="width: 100%; height: 100%;"
    id="style3d-con"
    @dragover.prevent
    @mousedown="onLincproMouseDown"
    @mouseup="onLincproMouseUp"
    @drop="doDrop" />
</template>
<style scoped>
</style>
