<script setup lang="ts">
import { type ID, Style3d, type Style3dOptions } from '@repo/style3d';
import { useSelectionStore, useStyle3dStore } from '@repo/stores';
import { onUnmounted } from 'vue';

type Props = Partial<Pick<Style3dOptions, 'displayLevel' | 'backgroundColor'>>

const props = withDefaults(
  defineProps<Props>(), {
    displayLevel: 3,
    backgroundColor: '#31363B'
  });

const style3dStore = useStyle3dStore();

const onLoaded = () => {
  style3dStore.loaded = true;
};

const onReady = (isReady: boolean) => {
  style3dStore.ready = isReady;
};

const selectionStore = useSelectionStore();
const onMaterialSelected = (id: ID) => {
  selectionStore.setSelectedFabric(id);
};

onUnmounted(() => {
  style3dStore.reset();
});
</script>
<template>
  <div class="viewer-container">
    <Style3d
      v-bind:options="props"
      @loaded="onLoaded"
      @ready="onReady"
      @material-selected="onMaterialSelected"
    />
  </div>
</template>
<style scoped>
.viewer-container {
  width: 100%;
  height: 100%;
}
</style>
