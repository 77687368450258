import type {
  FabricPath,
  ID,
  Material,
  MatrixInfo,
  Shape,
  Style3dOptions,
  Style3dSDKInstance,
  UVInfo
} from './typing.ts';
import { reactive, ref } from 'vue';
import { getMMNum } from '@repo/utils';
import { getFabricPath, getPieceShapeMap } from './utils.ts';
import { omit } from 'lodash-es';
import { handleUvAndMatrix, releaseUvInfo } from './matrix.ts';
import { toReactive } from '@vueuse/core';

declare global {
  interface Window {
    Style3D: any;
  }
}

// 0:Current, 1:Front, 2:Left, 3:Right, 4:Back, 5:Custom
type Angle = 0 | 1 | 2 | 3 | 4 | 5;

let style3dSDKInstance: Style3dSDKInstance;

let baseUrl = '';

const style3dSDK = new Proxy({}, {
  get(_target, prop) {
    if (!style3dSDKInstance) {
      throw new Error('Style3dSDK is not initialized. Please initialize it before using.');
    }
    return (style3dSDKInstance as any)[prop];
  },
  set(_target, prop, value) {
    if (!style3dSDKInstance) {
      throw new Error('Style3dSDK is not initialized. Please initialize it before using.');
    }
    (style3dSDKInstance as any)[prop] = value;
    return true;
  }
}) as Style3dSDKInstance;

function setTransprarencyRenderEnabled(transprarency: boolean = true) {
  style3dSDK.lincpro?.setTransprarencyRenderEnabled(transprarency);
}

async function setMaterialInfoOffset(shapes: Shape[], _materialList: Material[]) {
  if (!shapes || shapes.length == 0) {
    return;
  }

  let copiedUvInfo: UVInfo | null;
  let copiedMatrixInfo: MatrixInfo | null;
  copiedUvInfo = await style3dSDK.getClothesUvInfo();
  copiedMatrixInfo = await style3dSDK.getClothesGeometryFabricMatrix();

  let newMatrixInfo: MatrixInfo | null;
  newMatrixInfo = handleUvAndMatrix(shapes, _materialList, copiedUvInfo, copiedMatrixInfo);
  // 使用完数据后，解除引用
  releaseUvInfo(copiedUvInfo);
  copiedUvInfo = null;
  copiedMatrixInfo = null;

  //更新id
  await style3dSDK.setClothesGeometryFabricMatrix(newMatrixInfo!);
  newMatrixInfo = null;

  for (let i = 0; i < shapes.length; i++) {
    const item = shapes[i]!;
    if (item.pos && (item.pos.x || item.pos.y) && item.dpm && item.width && item.height) {
      // 偏移不为空 进行偏移ou
      const materials = _materialList.filter((v) => v.name === item.piece);
      if (!materials || materials.length === 0) {
      } else {
        // 选中
        await style3dSDK!.selectMaterial(materials[0]!.id, -1, false, 0);
        if (item.pos.x && item.dpm) {
          // x偏移
          const width = getMMNum(item.dpm.x, item.width);
          const x = item.pos.x;
          item.pos.x = width ? Number(((x % width) / width).toFixed(13)) : 0;

          await style3dSDK!.setFabricOffsetX(item.pos.x);
        }
        if (item.pos.y && item.dpm) {
          // x偏移
          const height = getMMNum(item.dpm.y, item.height);
          let y = item.pos.y;
          y = height ? Number(((height - (y % height)) / height).toFixed(13)) : 0;
          await style3dSDK!.setFabricOffsetY(y);
        }
      }
    }
  }
}

const instanceWrapper = reactive({
  instance: null as (ReturnType<typeof createStyle3dSDKInstance> | null)
});

function createStyle3dSDKInstance() {
  const loading = ref(false);
  const isReady = ref(false);

  async function initStyle3d(ops: Style3dOptions) {
    const options = omit(ops, 'baseUrl');
    const dom = document.getElementById('style3d-con');
    style3dSDKInstance = new window.Style3D.Viewer({
      dom,
      displayLevel: options.displayLevel,
      custom: {
        backgroundStyle: {
          color: options.backgroundColor
        }
      },
      events: {
        onReady: () => {
          console.timeEnd('style3d onReady');
          isReady.value = true;
        },
        ...options.events
      },
      config: {
        disableGeoBackPart: true
      }
    });
    setTransprarencyRenderEnabled();
    await style3dSDK.setImageProcessStrategy(0);
  }

  async function loadSco(scoSrc: string) {
    isReady.value = false;
    await style3dSDK.load(scoSrc, null, null, null);
  }

  interface FabricLoadOption {
    fabricRootPath: string;
    fabric?: {
      type: string,
      id: string,
      originUrl?: string
    };
    color?: string;
    resolution?: 'sd' | 'hd';
  }

  async function loadFabric(shape: Shape[], option: FabricLoadOption) {
    if (!shape || shape.length === 0) {
      return [];
    }

    const { fabricRootPath, resolution = 'sd', fabric } = option;
    baseUrl = fabricRootPath;

    await style3dSDK.setRenderEnabled(false);
    const map = getPieceShapeMap(shape);
    const materialList = await style3dSDK.getMaterialList();
    const shapes = materialList
      .filter(({ name, id }) => map.has(`${id}`) || map.has(name))
      .map(item => ({
        ...item,
        ...(map.has(`${item.id}`) ? map.get(`${item.id}`) : map.get(item.name)),
        ...(fabric ? { fabric } : {}),
        id: item.id
      }))
      .filter((shape) => shape !== undefined)
      .map(item => ({ ...item, name: item.onlyColor ? '' : item.name }));

    const materialIds: ID[] = [];
    const fabricsInfoList: FabricPath[] = [];
    const colors: string[] = [];
    for (const shape of shapes) {
      const fabricPath = getFabricPath(shape, baseUrl, resolution);

      materialIds.push(shape.id);
      fabricsInfoList.push(fabricPath);

      const c = (shape?.fabric && option?.color) ? option.color : shape.color ? shape.color : '';
      colors.push(c);
    }
    await style3dSDK.changeFabricsByMaterials(materialIds, fabricsInfoList, colors);
    await setMaterialInfoOffset(shape, materialList);
    await style3dSDK.setRenderEnabled(true);
    await style3dSDK.clearMaterialSelection();
    await style3dSDK.clearHistorySteps();

    return shapes as Shape[];
  }

  async function resetCameraViewState() {
    // 连续调用两次可以避免CameraViewState不能正确归位的问题
    await style3dSDK.setCameraViewState(0);
    await style3dSDK.setCameraViewState(0);
  }

  async function changeFabricColor(color: string) {
    return await style3dSDK.changeFabricColor(color);
  }

  async function getScreenshot(angle: Angle = 1) {
    return await style3dSDK.getScreenshot(angle, false, false);
  }

  async function selectMaterial(id: ID, highlightDelayMillisecond = -1, autoLocateEnabled = false, flashTimes = 0) {
    await style3dSDK.selectMaterial(id, highlightDelayMillisecond, autoLocateEnabled, flashTimes);
  }

  async function clearMaterialSelection() {
    await style3dSDK.clearMaterialSelection();
  }

  async function changeFabric(fabricInfo: FabricPath) {
    await style3dSDK.changeFabricsByMaterials([fabricInfo.id], [fabricInfo]);
  }

  async function setFabricRotation(angle: number) {
    return await style3dSDK.setFabricRotation(angle);
  }

  async function setFabricCycle(cycle: number) {
    return await style3dSDK.setFabricCycle(cycle);
  }

  async function getMaterialList() {
    return await style3dSDK.getMaterialList();
  }

  async function getFabricState() {
    return await style3dSDK.getFabricState();
  }

  async function setRenderEnabled(enabled: boolean) {
    await style3dSDK.setRenderEnabled(enabled);
  }

  function getStyle3dSDKInstance() {
    if (!style3dSDKInstance) {
      throw new Error('Style3dSDK is not initialized. Please initialize it before using.');
    }
    return style3dSDKInstance;
  }

  return {
    style3dSDK,
    isReady,
    initStyle3d,
    loading,
    loadSco,
    loadFabric,
    resetCameraViewState,
    getScreenshot,
    selectMaterial,
    clearMaterialSelection,
    changeFabric,
    setFabricRotation,
    setFabricCycle,
    changeFabricColor,
    getMaterialList,
    getFabricState,
    setRenderEnabled,
    getStyle3dSDKInstance
  };
}

export function useStyle3d() {
  if (!instanceWrapper.instance) {
    instanceWrapper.instance = toReactive(createStyle3dSDKInstance());
  }
  return instanceWrapper.instance;
}
